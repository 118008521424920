export const AuthReducer = (initialState, action) => {
  console.log("action.type", action.type);
  switch (action.type) {
    case "REQUEST_START":
      return {
        ...initialState,
        loading: true,
        successMessage: null,
        errorMessage: null
      }
    case "USER_INFO":
      return {
        ...initialState,
        user: action.payload.user
      }
    case "LOGIN":
      return {
        ...initialState,
        user: action.payload.user,
        loading: false
      }
    case "LOGOUT":
      return {
        ...initialState,
        user: { modules: [] },
        successMessage: null,
        errorMessage: null
      }
    case "NOTIFICATIONS":
      return {
        ...initialState,
        notifications: action.payload.notifications
      }
    case "SOCKETIO":
      console.log("SOCKETIO");
      return {
        ...initialState,
        socketIo: action.payload.socketIo
      }
    case "ERROR":
      return {
        ...initialState,
        loading: false,
        successMessage: null,
        errorMessage: action.error
      }
    case "SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null,
        successMessage: action.success
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
