import axios from 'axios'
import { API_URL } from '../constants'

const http = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

http.interceptors.response.use((response) => {
  if(response.config.url === '/user'){
    if(response.data.result.length > 0 ){
      let aux = response.data.result.filter(e => e.email !==  "" || e.expoToken !== "" || e.fullName !== "")
      let aux2 = response.data.result.filter(e => e.email ===  "" && e.expoToken === "" && e.fullName === "")
      response.data.result = aux.concat(aux2)
    }
  }
  return response
}, function (error) {
  return Promise.reject(error.response)
})

http.interceptors.request.use(
  config => {
    let token = null
    try {
      token = localStorage.getItem('@TOKEN')
    } catch (error) {
      //hanlde error
    }

    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    Promise.reject(error)
  })

export default http
